/* Style the main container */

.signup-page-container {
    background-color: #ffffff;
    background-size: cover;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    margin-top: -12%;
  }

  @media (max-width: 768px) {
    .signup-page-container {
      max-width: 80%; /* Adjusted size for smaller screens */
    }
  }
  
  /* Style the form elements */
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 80%; /* Set the width to 100% to fit within the container */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}
  
  .alrHave{
    font-size: 14px;
    color: #888;
  }
  
  /* Style the "Sign up" button */
  button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Style the "Already have an account?" link */
  p a {
    color: #007BFF;
    text-decoration: none;
  }
  
  /* Style the alert message */
  .alert {
    color: #ff0000;
    font-size: 14px;
  }
  
  /* Style the background */
  .bg-2 {
    background-image: url('/src/assets/alt-BG.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  
  .errr{
    color: red;
  }