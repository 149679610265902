.hhc-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    overflow: hidden;
}

.hhc-page-title{


    font-size: 6vw;
    z-index: 4;


    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: black;

    opacity: 0;

    animation-name: fadeInUp;
    animation-duration: 0s;
    animation-fill-mode: forwards;
    animation-play-state: paused;
}

#hi{
    font-size: 12pt;
    font-weight: 300;

    color: gray;
}



.event-desc{

    margin-top: -1vh;
    font-size: 1.5rem;
    z-index: 4;
    font-weight: 300;
    color: rgb(54, 54, 54);

    text-align: center;
    max-width: 1000px;

    /* background: -webkit-linear-gradient(30deg, #000000, #424242);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    opacity: 0;

    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}


.notransition * { 
    transition: none !important; 
  } 
  

.main{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 100vw;
    height: 100vh;
    background-color: #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    overflow: hidden;


}


