/* Event Component CSS */
.event-card{
    width: 301px;
    height: 400px;
    border-radius: 13px;
    margin: 8px;
    transition: 0.3s;
    cursor: pointer;
    position: relative;

    background-color: rgba(255, 255, 255, 0.048);
    border: 1px solid rgba(255, 255, 255, 0.068);
    color: rgb(0, 0, 0)
}

.event-card:hover{
    transform: scale(1.06);
}

.event-card:hover .population{
    transform: translateY(-5px);
    opacity: 100%;
}

.event-thumbnail{
    width: 100%;
    height: 60%;

    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.event-info{
    margin: 30px;
}

.event-date{
    margin-bottom: -5px;
}

.event-date, .event-location{
    opacity: 60%;
}

.population{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.201);
    border: 1px solid rgba(255, 255, 255, 0.068);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin: 15px 10px;
    color: white;
    opacity: 0%;
    transition: 0.3s;
}

.population-text{
    margin-left: 5px;
}

/* Thumbnail IDs */

#darshana{
    background-image: url("../assets/events/darshana.jpeg");
}

#ak_ramayan{
    background-image: url("../assets/events/AK_ramayan.jpg");
}

#hhc_thumb{
    background-image: url("../assets/events/hhc_thumb.JPG");
}

#hcc_may_thumb{
    background-image: url("../assets/events/hcc_may_thumb.jpg");
}

/* Events Page CSS */
.events-page-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events-page-title{
    margin-top: 10vh;
    margin-bottom: 6vh;
    font-size: 2vw;
    z-index: 4;

    font-family: 'Inter', sans-serif;
    font-weight: 400;

    /* background: -webkit-linear-gradient(30deg, #009dff, #6600ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    background: -webkit-linear-gradient(30deg, #000000, #424242);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    opacity: 0;

    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;

}

.events-page-content{
    width: 951px;
    margin-top: -6vh;

    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
    z-index: 4;
    opacity: 0;

}

.highlight-event{
    width: 100%;
    height: 450px;
    background-color: rgba(255, 255, 255, 0.11);
    border: 1.5px solid rgba(255, 255, 255, 0.068);
    border-radius: 13px;

    background-image: url("../assets/events/sundarkhand.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
}

.highlight-event:hover{
    transform: scale(1.03);
}

.highlight-event-tags{
    display: flex;
    position: absolute;
    right: 5px;
    bottom: 15px;
}

.highlight-event-tags > p{
    margin: 0 10px;
    background-color: rgba(0, 0, 0, 0.201);
    border: 1px solid rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(10px);
    font-weight: 400;
    color: white;
    font-size: 14px;
    padding: 8px 18px;
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.402);
}

.cool-tag{
    /* background: linear-gradient(30deg, rgb(114, 222, 255), rgb(255, 113, 196)); */
    background: linear-gradient(30deg, rgb(255, 104, 44), rgb(255, 226, 133));
}

.events-grid{
    display: flex;
    justify-content: space-between;
}

.timing-title{
    margin-top: 50px;
    opacity: 90%;
    margin-left: 0.5vw;
}

@media screen and (max-width: 900px){
    .highlight-event{
        background-image: url("../assets/events/sundarkhand.jpg");
    }

    .events-page-title{
        font-size: 6vw;
    }

    .alt-subtitle{
        font-size: 3vw;
        max-width: 70vw;
    }

    .events-page-content{
        width: 95vw;
    }

    .timing-title{
        font-size: 3vw;
        margin-bottom: 15px;
    }

    .highlight-event-tags > p{
        font-size: 2vw;
    }

    .events-grid{
        align-items: center;
        flex-direction: column;
    }

    .event-card{
        width: 95vw;
        margin: 10px 0;
    }

    .event-card:hover{
        transform: scale(1);
    }
}


.events-page-items{
    margin-top: 5vh;
    margin-bottom: 6vh;
    font-size: 1.6vw;
    z-index: 4;

    font-family: 'Times New Roman', sans-serif;
    font-weight: 100;

    /* background: -webkit-linear-gradient(30deg, #009dff, #6600ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    background: -webkit-linear-gradient(30deg, #000000, #424242);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    opacity: 0;

    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}