.nav{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 40px;
    z-index: 1000;
    background-color: rgba(0, 80, 1, 0.027);
    border-bottom: .5px solid black;
    backdrop-filter: blur(1000px);
}

.nav-buttons > *{
    margin: 0 25px;
    cursor: pointer;
    transition: 0.3s;
    opacity: 75%;
    color: black;
}

.nav-buttons > *:hover{
    transform: scale(1.3);
    opacity: 100%;
}

.nav-buttons{
    font-size: 13px;
    display: flex;
    /* background-color: blue; */
    align-items: center;
    height: 100%;
    justify-content: space-between;
    margin-left: -100px; /*default 0 */
    margin-right: -285px; /* default -180 */
    

    /* border: 2px solid white; */
    
}

.nav-highlight{
    position: absolute;
    right: 0;
}

.nav > .logo{
    width: 30px;
    max-height: 30px;
    position: absolute;
}

@keyframes dropIn {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}

/* Coming Soon css */
.coming-soon{
    position: fixed;
    top: 5vh;
    left: 3vw;
    z-index: 100000;
    width: 200px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.112);
    backdrop-filter: blur(10px);
    color: rgb(255, 255, 255);
    font-size: 13px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    opacity: 0;

    animation-name: dropInDropOut;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes dropInDropOut {
    0%{
        transform: translateY(-100%);
        opacity: 0;
    }
    20%{
        transform: translateY(0);
        opacity: 1;
    }
    80%{
        transform: translateY(0);
        opacity: 1;
    }
    100%{
        transform: translateY(-100%);
        opacity: 0;
    }
}

@keyframes slideInRight {
    0%{
        transform: translateX(40%);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    0%{
        transform: translateX(0);
        opacity: 1;
    }
    100%{
        transform: translateX(40%);
        opacity: 0;
    }
}

.loading-bar{
    position: fixed;
    left: 0;
    top: 40px;
    width: 0;
    height: 3px;
    background: -webkit-linear-gradient(0deg, #fcb454, #f8891b);
    z-index: 100;
}

.load-gal{
    animation-name: load;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.load-fast{
    animation-name: load;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes load {
    0%{
        width: 0;
    }
    60%{
        width: 100vw;
        opacity: 1;
        height: 3px;
        transform: translate(0);
    }
    100%{
        width: 100vw;
        transform: translateY(-40px);
        height: 40px;
        opacity: 0;
    }
}

@keyframes mobile-load {
    0%{
        width: 0;
    }
    60%{
        width: 100vw;
        opacity: 1;
        height: 3px;
        transform: translateY(0);
    }
    100%{
        width: 100vw;
        opacity: 0;
        height: 10px;
    }
}

/* mobile css */


@media screen and (min-width: 900px) {
    .menu-btn{
        text-align: center;
    }
}

@media screen and (max-width: 900px) {
    .mobile-nav{
        position: fixed;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: right;
        width: 100vw;
        padding-top: 4px;
        height: 40px;
        z-index: 1000;
        /* background-color: rgba(0, 0, 0, 0.107); */
        /* backdrop-filter: blur(10px); */
    }
    
    .mobile-nav > .logo{
        width: 30px;
        max-height: 30px;
        position: absolute;
        left: 25px;
    }
    
    .open-menu{
        position: relative;
        right: 25px;
        width: 25px;
        height: auto;
        cursor: pointer;
        color: white;
        background-color: rgba(255, 255, 255, 0.11);
        border-radius: 8px;
        padding: 8px;
        text-align: center;
        backdrop-filter: blur(10px);
    }
    
    .menu-container{
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 60vw;
        background-color: rgba(255, 255, 255, 0.055);
        z-index: 100000;
        backdrop-filter: blur(30px);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 60px;
        padding-top: 50px;
        border-radius: 20px 0 0 20px;
        opacity: 0;
    }

    .menu-enter{
        animation-name: slideInRight;
        animation-duration: 0.4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .menu-leave{
        animation-name: slideOutRight;
        animation-duration: 0.4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .menu-btn{
        font-size: 25px;
        font-weight: 400;
        cursor: pointer;
        color: white;
    }

    .close-menu{
        position: absolute;
        top: 30px;
        left: 40px;
        width: 30px;
        height: auto;
        cursor: pointer;
        color: white;
    }

    .menu-highlight{
        background: -webkit-linear-gradient(30deg, #72deff, #ff71c4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .loading-bar{
        top: 0;
    }

    .load-gal{
        animation-name: mobile-load;
    }
    
    .load-fast{
        animation-name: mobile-load;
    }
}

#small_topcorner{
    /* width: 4%;
    height: 4%; */
    /* width: 11%;
    height: 11%; */

    width: 85%;
    height: 85%;
    
    
}

.top_logo{
    margin-left: -575px;
    margin-right: 350px;

}

.toplogo{

    
    display: flex;
    align-items: center;
    justify-content: center;

    width: 45px;
    height: 45px;

    left: 500px;
}

.su{
    position: relative;
    font-size: 13px;
    display: flex;
    /* background-color: blue; */
    align-items: center;
    height: 100%;
    justify-content: space-between;
    /* margin-left: -50px; /*default 0 */
    /* margin-right: -285px; default -180 */
    left: 270px;
}