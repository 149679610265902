.footer-container{
    width: 100vw;
    height: 80px;
    margin-top: 10vw;
    color: white;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}

.foot-branding img{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-left: -10px;
    z-index: 100;
}

.foot-branding span{
    margin-right: 20px;
    z-index: 100;
}

.foot-branding{
    display: flex;
    align-items: center;
    margin-left: 30px;
    z-index: 100;
}

.foot-social{
    margin-right: 10px;
    cursor: pointer;
    z-index: 100;
}

.footer-container > p{
    margin-right: 30px;
    z-index: 100;
}

@media screen and (max-width: 900px) {

    .foot-branding{
        font-size: 2vw;
    }

    .footer-container > p{
        font-size: 2vw;
    }

    .foot-branding{
        width: 60%;
    }

}