/* new features css */
.feat-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 4;
}

.big-feat{
    z-index: inherit;
    /* background-color: red; */
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
}

#big-feat-text{
    margin-left: 50px;
    background-color: rgba(255, 255, 255, 0.082);
    border: 1px solid rgba(255, 255, 255, 0.212);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
    padding: 20px;
    border-radius: 10px;
}

.big-feat-img{
    width: 350px;
    height: auto;
}

.feat-title{
    font-size: 25px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    transition: 0.3s;
}

.feat-sub{
    opacity: 50%;
    font-weight: 200;
    font-size: 15px;
}

.feat-icon{
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.144);
    border: 1px solid rgba(255, 255, 255, 0.171);
    border-radius: 5px;

    transition: 0.2s;
}

.feat-list{
    z-index: 4;
    display: flex;
    margin-top: 5vh;
    /* background-color: red; */
    align-self: center;
    align-items: center;
    margin-left: 20px;
}

.feat{
    padding: 20px;
    border-radius: 10px;
    margin: 0 10px;
    border: 1px solid rgba(255, 255, 255, 0);
    position: relative;
    transition: 0.2s;
    color: white;
}

.feat:hover{
    background-color: rgba(255, 255, 255, 0.082);
    border: 1px solid rgba(255, 255, 255, 0.212);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
    transform: translateY(-5px);
}

.long-feat{
    width: 50%;
}

.feat:hover .feat-icon{
    box-shadow:
    inset 0 0 5px rgba(255, 255, 255, 0.263),      /* inner white */
    inset 2px 0 8px rgba(255, 0, 255, 0.662),   /* inner left magenta short */
    inset -2px 0 8px #0ff,  /* inner right cyan short */
    inset 2px 0 30px #f0f,  /* inner left magenta broad */
    inset -2px 0 30px #0ff, /* inner right cyan broad */
    0 0 5px rgba(255, 255, 255, 0.114),            /* outer white */
    -1px 0 8px #f0f,        /* outer left magenta */
    1px 0 8px #0ff; 
}

.big-feat-icon{
    box-shadow:
    inset 0 0 5px rgba(255, 255, 255, 0.263),      /* inner white */
    inset 2px 0 8px rgba(255, 0, 255, 0.662),   /* inner left magenta short */
    inset -2px 0 8px #0ff,  /* inner right cyan short */
    inset 2px 0 30px #f0f,  /* inner left magenta broad */
    inset -2px 0 30px #0ff, /* inner right cyan broad */
    0 0 5px rgba(255, 255, 255, 0.114),            /* outer white */
    -1px 0 8px #f0f,        /* outer left magenta */
    1px 0 8px #0ff; 
}

.book-us{
    position: absolute;
    top: 17px;
    font-size: 14px;
    right: 25px;
    background-color: rgba(255, 255, 255, 0.144);
    border: 1px solid rgba(255, 255, 255, 0.171);
    border-radius: 100px;
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.712);
    transition: 0.2s;
}

.feat:hover .book-us{
    color: white;
}

.book-us:hover{
    box-shadow:
    inset 0 0 5px rgba(255, 255, 255, 0.263),      /* inner white */
    inset 2px 0 8px rgba(255, 0, 255, 0.662),   /* inner left magenta short */
    inset -2px 0 8px #0ff,  /* inner right cyan short */
    inset 2px 0 30px #f0f,  /* inner left magenta broad */
    inset -2px 0 30px #0ff, /* inner right cyan broad */
    0 0 5px rgba(255, 255, 255, 0.114),            /* outer white */
    -1px 0 8px #f0f,        /* outer left magenta */
    1px 0 8px #0ff; 
}

.connect-feat{
    z-index: 4;
    margin-top: 100px;
    width: 90vw;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.connect-img{
    height: 70vh;
    width: auto;
}

.connect-feat-text{
    /* background-color: red; */
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    background-color: rgba(255, 255, 255, 0.082);
    border: 1px solid rgba(255, 255, 255, 0.212);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);

    padding: 0 10vw;
    border-radius: 10px;
    color: white;
}

.connect-feat-text p{
    text-align: center;
    max-width: 420px;
}

.connect-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: red; */
}

.connect-icon{
    margin-top: 10vh;
}

.notif-img{

    position: absolute;

    width: 19vw;
    height: auto;
    background-color: rgba(255, 255, 255, 0.082);
    padding: 2vh 1.5vw;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.174);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
    margin-top: 5vh;
}

.phone-notif-img{
    width: 23vw;
    height: auto;

    border-bottom: 1px solid rgba(255, 255, 255, 0.048);
}

.trusts-page{
    z-index: 4;
    /* background-color: red; */
    border-top: 1px solid rgba(255, 255, 255, 0.174);
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trusts-title{
    font-size: 2vw;
    z-index: 4;
    padding: 5px;
    margin-top: 10vh;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    background: -webkit-linear-gradient(30deg, #72deff, #ff71c4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.trusts-list{
    display: flex;
    /* background-color: blue; */
    align-items: center;
    justify-content: space-between;
}

.trust{
    width: 4vw;
    height: auto;
    margin: 0 3vw;
    opacity: 40%;
    transition: 0.3s;
}

.brand-name{
    margin-top: calc(5vh - 10px);
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.174);
    background-color: rgba(255, 255, 255, 0.082);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
    opacity: 0;
    transition: 0.2s;
}

.trust-group:nth-child(2) p, .trust-group:nth-child(4) p{
    position: relative;
    bottom: 3vh;
    color: white;
}

.trust:hover ~ .brand-name{
    opacity: 1;
    transform: translateY(10px);
}

.trust:hover{
    opacity: 1;
}

@media screen and (max-width: 900px) {
    .big-feat{
        flex-direction: column;
    }

    #big-feat-text{
        margin-left: 0;
        text-align: center;
        margin-bottom: 50px;
    }
    
    .feat-list{
        margin: 0;
        flex-direction: column;
    }

    .feat{
        margin: 10px 0;
    }

    .long-feat{
        width: auto;
    }

    .connect-feat-text{
        height: 500px;
    }

    .connect-img {
        display: none;
    }

    .phone-notif-img{
        height: 160px;
        width: auto;
    }

    .notif-img{
        width: 70vw;
    }

    .trusts-title{
        font-size: 6vw;
    }

    .trusts-list{
        margin-top: 4vh;
        margin-bottom: 10vh;
    }

    .trust{
        width: 40px;
    }
}