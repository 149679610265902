/* Profile.css */

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75vh;
    width: 100%;
    justify-content: center;
  }
  
  .profile-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* margin: 20px; */
    max-width: 400px;
    text-align: center;

  }
  
  .profile-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: black;
  }
  
  .profile-email {
    color: #000000;
  }
  
  .profile-uid {
    margin-top: 10px;
    font-size: 18px;
    color: black;
  }
  
  /* Add more styles and customize the profile page as needed */
  