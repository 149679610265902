.gallery-container{
    width: 100vw;
    height: auto;
    /* background-color: red; */
    z-index: 4;
    position: absolute;
    top: 50px;
    left: 0;

    display: flex;
    flex-direction: column;
}

.gal-nav-center{
    /* background-color: red; */
    width: 100vw;
    height: calc(13vh);
    position: fixed;
    z-index: 5;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.gal-nav{
    z-index: 5;
    height: 100%;
    opacity: 0;

    z-index: inherit;
    width: fit-content;
    display: flex;
    background-color: rgba(255, 255, 255, 0.048);
    border: 1px solid rgba(255, 255, 255, 0.068);

    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 2px 5px;

    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.gal-nav-btn{
    z-index: 5;
    height: calc(100% - 10px);
    width: 4vw;
    margin: 5px;
    opacity: 50%;
    border-radius: 5px;

    cursor: pointer;
    transition: 0.2s;
    border: 1px solid rgba(255, 255, 255, 0.068);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gal-nav-btn:hover{
    opacity: 1;
}

.gal-nav-btn[data-hover="true"]{
    opacity: 1;
}

.gal-title{
    margin-bottom: 5px;
    padding: 8px 14px;
    background-color: rgba(255, 255, 255, 0.048);
    border: 1px solid rgba(255, 255, 255, 0.068);
    backdrop-filter: blur(20px);
    border-radius: 4px;
    font-size: 15px;
}

.visible{
    opacity: 1;
}

.gal-row{
    height: 80vh;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    opacity: 0;
    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;

    opacity: 0;
    animation-delay: 0.7s;
    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.gal-row:nth-child(2){
    flex-direction: row-reverse;
    animation-delay: 0.9s;
}

.gal-item{
    height: 100%;
    /* background-color: blue; */
    margin: 0 10px;
    border-radius: 10px;
    object-fit: cover;
}

.gal-item:nth-child(1){
    width: calc(33.333% - 20px);
}

.gal-item:nth-child(2){
    width: calc(66.666% - 20px);
}

/* #gal-nav-noto{
    background-image: url('../assets/events/frightnitenoto.jpg');
}

#gal-nav-spring{
    background-image: url('../assets/events/springspec.png');
}

#gal-nav-litesout{
    background-image: url('../assets/events/LitesOut-poster.png');
} */

@media screen and (max-width: 900px){
    .gal-nav-btn{
        width: 100px;
    }

    .gal-row{
        flex-direction: column;
        margin: 0;
    }

    .gal-row:nth-child(2){
        flex-direction: column;
        animation-delay: 0.9s;
    }

    .gal-item{
        width: 100%;
        margin: 10px 0;
    }

    .gal-item:nth-child(1){
        width: 100%;
    }
    
    .gal-item:nth-child(2){
        width: 100%;
    }
    
}